import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Autocomplete
} from '@mui/material'
import { Button } from '@mui/material'
import axios from 'axios'
import * as yup from 'yup'
import haxios from '../../lib/haxios'

const schema = yup.object().shape({
  CustomerKeyCode: yup.string().required('Customer Key Code is required'),
  FactorBranch: yup.string().required('Factor Branch is required'),
  GarageUserName: yup
    .string()
    .required('Garage Username is required')
    .matches(/^[a-zA-Z0-9!@#$%^&*()]+$/, 'Only alphanumeric and special characters allowed, no spaces'),
  GaragePersonInCharge: yup.string().required('Person in charge is required'),
  Address: yup.string().required('Address is required'),
  Active: yup.boolean()
})

const AddCustomerModal = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    CustomerKeyCode: '',
    FactorBranch: '',
    GarageUserName: '',
    GaragePersonInCharge: '',
    Address: '',
    Active: true
  })
  const [customers, setCustomers] = useState([])
  const [branches, setBranches] = useState([])
  const [errors, setErrors] = useState({})

  const customerValues = customers.map((cust) => ({ value: cust.KeyCode, label: `[${cust.KeyCode}] ${cust.Name}` }))
  const branchValues = branches.map((br) => ({ value: br.Branch, label: `[${br.Branch}] ${br.Name}` }))

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [customersRes, branchesRes] = await Promise.all([
          haxios.get('https://api.aa-vision.com/api/map/customers/slim/active'),
          haxios.get('https://api.aa-vision.com/api/map/branches')
        ])
        setCustomers(customersRes)
        setBranches(branchesRes)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [])

  const handleCustomerChange = async (event) => {
    const value = event.target.value
    const cust = customers.filter((el) => el.KeyCode === value).pop()
    const address = [cust.Addra, cust.Addrb, cust.Addrc, cust.Addrd, cust.Addre, cust.PCode].filter(Boolean).join('\n')
    setFormData((prev) => ({ ...prev, CustomerKeyCode: value, Address: address }))
  }

  const handleSubmit = async () => {
    try {
      await schema.validate(formData, { abortEarly: false })

      try {
        await haxios.post('https://api.aa-vision.com/api/connectplus/garageuser/', {
          GarageName: customers.filter((el) => el.KeyCode === formData.CustomerKeyCode).pop().Name,
          GaragePersonInCharge: formData.GaragePersonInCharge,
          GarageUserName: formData.GarageUserName,
          CustomerKeyCode: formData.CustomerKeyCode,
          Address: formData.Address,
          Active: formData.Active,
          CreatedUserId: crypto.randomUUID(),
          FactorBranch: formData.FactorBranch,

          GarageUserId: 'e6198348-40ca-c01e-6277-5555248e6843',
          CompanyId: null,
          Tel: '',
          Email: '',
          CreatedDatetime: null,
          ModifiedDatetime: null,
          ModifiedUserId: null,
          EnquiryResponseNotifications: true,
          OrderCompleteNotifications: true,
          RejectedNotifications: true
        })
      } catch (e) {}

      onClose()
    } catch (err) {
      const validationErrors = {}
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message
      })
      setErrors(validationErrors)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Customer</DialogTitle>
      <DialogContent>
        <Autocomplete
          fullWidth
          options={customerValues}
          value={customerValues.find((option) => option.value === formData.CustomerKeyCode) || null}
          onChange={(event, newValue) => {
            if (newValue) {
              handleCustomerChange({ target: { value: newValue.value } })
            }
          }}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              label="Customer Key Code"
              error={!!errors.CustomerKeyCode}
              helperText={errors.CustomerKeyCode}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />

        <Autocomplete
          fullWidth
          options={branchValues}
          value={branchValues.find((option) => option.value === formData.FactorBranch) || null}
          onChange={(event, newValue) => {
            if (newValue) {
              setFormData((prev) => ({ ...prev, FactorBranch: newValue.value }))
            }
          }}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              label="Factor Branch"
              error={!!errors.FactorBranch}
              helperText={errors.FactorBranch}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />

        <TextField
          fullWidth
          margin="normal"
          label="Garage Username"
          value={formData.GarageUserName}
          onChange={(e) => setFormData((prev) => ({ ...prev, GarageUserName: e.target.value }))}
          error={!!errors.GarageUserName}
          helperText={errors.GarageUserName}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          fullWidth
          margin="normal"
          label="Person in Charge"
          value={formData.GaragePersonInCharge}
          onChange={(e) => setFormData((prev) => ({ ...prev, GaragePersonInCharge: e.target.value }))}
          error={!!errors.GaragePersonInCharge}
          helperText={errors.GaragePersonInCharge}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          fullWidth
          margin="normal"
          label="Address"
          multiline
          rows={4}
          value={formData.Address}
          onChange={(e) => setFormData((prev) => ({ ...prev, Address: e.target.value }))}
          error={!!errors.Address}
          helperText={errors.Address}
          InputLabelProps={{ shrink: true }}
        />

        {/* <FormControlLabel
          control={
            <Checkbox
              checked={formData.Active}
              onChange={(e) => setFormData((prev) => ({ ...prev, Active: e.target.checked }))}
            />
          }
          label="Active"
        /> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddCustomerModal
