import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import axios from 'axios'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { GlobalContext } from '../context/GlobalContext'
import useApi from '../hooks/useApi'
import haxios from '../lib/haxios'
import EnquiryItem from './EnquiryItem'
import useLockStore from '../stores/useLockStore'

const LockDebug = () => {
  const locks = useLockStore((state) => state.locks)

  return <div>{JSON.stringify(locks)}</div>
}

const Enquiries = () => {
  const { token, username } = useContext(GlobalContext)
  const [startDate, setStartDate] = useState(dayjs().startOf('week').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(dayjs().endOf('week').format('YYYY-MM-DD'))
  const [searchTerm, setSearchTerm] = useState('')
  const [showOnlyBackordered, setShowOnlyBackordered] = useState(false)

  const [branchFilter, setBranchFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('')

  const [statusFilters, setStatusFilters] = useState({
    'FactorUnread': true,
    'Garage Confirmed': true,
    'Waiting': true,
    'Order': true,
    'Ordered': false,
    'Quoted': false,
    'Cancelled': false
  })

  // Status label mapping
  const statusLabels = {
    'FactorUnread': 'Unread Messages',
    'Garage Confirmed': 'Garage Confirmed',
    'Waiting': 'Waiting',
    'Order': 'Straight to Order',
    'Ordered': 'Ordered',
    'Quoted': 'Quoted',
    'Cancelled': 'Cancelled'
  }

  const {
    isLoading,
    isFetching,
    data: enquiries,
    refetch
  } = useApi({
    url: 'https://api.aa-vision.com/api/connectplus/factor/enquiries/',
    method: 'post',
    data: { StartDate: startDate, EndDate: endDate },
    refetch: 30 * 1000,
    initial: []
  })

  const { data: backordered, refetch: refetchBackordered } = useApi({
    url: '/connectplus/backordered/all',
    refetch: 30 * 1000,
    initial: []
  })

  if (isLoading) return null

  const toggleStatusFilter = (status) => {
    console.log('toggle', status)
    setStatusFilters((prev) => ({ ...prev, [status]: !prev[status] }))
  }

  const branches = [...new Set(enquiries.map((enquiry) => enquiry.Branch))]
  const statuses = [...new Set(enquiries.map((enquiry) => enquiry.Status))]

  console.log(enquiries, backordered)

  const getStatusCounts = () => {
    const statusCounts = {
      'FactorUnread': 0,
      'Garage Confirmed': 0,
      'Waiting': 0,
      'Ordered': 0,
      'Order': 0,
      'Quoted': 0,
      'Cancelled': 0,
      'Backordered': enquiries.filter((el) => backordered.some((bo) => bo.EnqId === el.EnquiryId)).length
    }

    for (const enquiry of enquiries) {
      if ((enquiry.FactorUnread || 0) > 0) statusCounts.FactorUnread++
      if (enquiry.Status === 'Garage Confirmed') statusCounts['Garage Confirmed']++
      if (enquiry.Status === 'Waiting') statusCounts.Waiting++
      if (enquiry.Status === 'Ordered') statusCounts.Ordered++
      if (enquiry.Status === 'Order') statusCounts.Order++
      if (enquiry.Status === 'Quoted') statusCounts.Quoted++
      if (enquiry.Status === 'Cancelled') statusCounts.Cancelled++
    }

    return statusCounts
  }

  const statusCounts = getStatusCounts()

  const filteredEnquiries = enquiries.filter((enquiry) => {
    if (Object.values(statusFilters).every((v) => !v)) return false

    const matchesBranch = !branchFilter || enquiry.Branch?.toLowerCase() === branchFilter.toLowerCase()
    const matchesStatus = !statusFilter || enquiry.Status?.toLowerCase() === statusFilter.toLowerCase()

    const customerName = enquiry.GarageName || ''
    const vehicleReg = enquiry.Reg || ''
    const parts = enquiry.Parts || ''
    const notes = enquiry.Notes || ''
    const garageAcctNo = enquiry.CustomerKeyCode || ''
    const garageName = enquiry.GarageName || ''

    const matchesSearch =
      customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicleReg.toLowerCase().includes(searchTerm.toLowerCase()) ||
      parts.toLowerCase().includes(searchTerm.toLowerCase()) ||
      notes.toLowerCase().includes(searchTerm.toLowerCase()) ||
      garageAcctNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      garageName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      enquiry.Status?.toLowerCase().includes(searchTerm.toLowerCase())

    const matchesStatusFilters =
      (statusFilters.FactorUnread && (enquiry.FactorUnread || 0) > 0) ||
      (statusFilters['Garage Confirmed'] && enquiry.Status === 'Garage Confirmed') ||
      (statusFilters.Waiting && enquiry.Status === 'Waiting') ||
      (statusFilters.Ordered && enquiry.Status === 'Ordered') ||
      (statusFilters.Order && enquiry.Status === 'Order') ||
      (statusFilters.Quoted && enquiry.Status === 'Quoted') ||
      (statusFilters.Cancelled && enquiry.Status === 'Cancelled')

    const isBackordered = !!backordered.filter((el) => el.EnqId === enquiry.EnquiryId).pop()

    return showOnlyBackordered
      ? isBackordered
      : matchesBranch && matchesStatus && matchesSearch && matchesStatusFilters && !isBackordered
  })

  let priorityEnquiries = []
  let otherEnquiries = []

  for (const enq of filteredEnquiries) {
    if (
      enq.Status === 'Order' ||
      enq.Status === 'Waiting' ||
      enq.Status === 'Garage Confirmed' ||
      (enq.FactorUnread || 0) > 0
    ) {
      priorityEnquiries.push(enq)
    } else {
      otherEnquiries.push(enq)
    }
  }

  priorityEnquiries = priorityEnquiries.sort((a, b) => {
    const aDate = new Date(a.DatetimeModified || a.DatetimeCreated)
    const bDate = new Date(b.DatetimeModified || b.DatetimeCreated)
    return aDate - bDate // oldest to newest
  })

  otherEnquiries = otherEnquiries.sort((a, b) => {
    const aDate = new Date(a.DatetimeModified || a.DatetimeCreated)
    const bDate = new Date(b.DatetimeModified || b.DatetimeCreated)
    return bDate - aDate // newest to oldest
  })

  return (
    <Grid container spacing={3} sx={{ height: '100%' }}>
      <Grid item xs={3}>
        <Box sx={{}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ lineHeight: 1 }}>
                Search
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ width: '100%' }}
                label="Type to search..."
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ width: '100%' }}
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ width: '100%' }}
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" sx={{ width: '100%' }}>
                <InputLabel id="branch-label">Branch</InputLabel>
                <Select
                  labelId="branch-label"
                  value={branchFilter}
                  onChange={(e) => setBranchFilter(e.target.value)}
                  label="Branch"
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {branches.map((branch) => (
                    <MenuItem key={branch} value={branch}>
                      {branch}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ paddingTop: 2 }}>
            <Box sx={{ paddingBottom: 1 }}>
              <Typography variant="h6">Filter by status</Typography>
            </Box>
            {Object.entries(statusFilters).map(([status, isActive]) => (
              <Box key={status} sx={{ paddingBottom: 2 }}>
                <Badge badgeContent={statusCounts[status]} color="primary">
                  <Button
                    variant={isActive ? 'contained' : 'outlined'}
                    sx={{
                      'backgroundColor': isActive ? '#06b6d4' : 'transparent',
                      'color': isActive ? 'white' : '#000',
                      'borderColor': '#b0bec5',
                      '&:hover': {
                        backgroundColor: isActive ? '#06b6d4' : '#f5f5f5'
                      }
                    }}
                    onClick={() => toggleStatusFilter(status)}
                  >
                    {statusLabels[status]}
                  </Button>
                </Badge>
              </Box>
            ))}

            {statusFilters['Garage Confirmed'] && (
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label={`Show only backordered (${statusCounts.Backordered})`}
                checked={showOnlyBackordered}
                onChange={(e) => setShowOnlyBackordered(e.target.checked)}
              />
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={9} style={{ maxHeight: '100%', overflow: 'auto' }}>
        <Grid container spacing={2} sx={{ paddingRight: 1 }}>
          <Grid item xs={24}>
            <Typography variant="h6" sx={{ lineHeight: 1 }}>
              Priority enquiries
            </Typography>
            {priorityEnquiries.length > 0 ? (
              priorityEnquiries.map((enquiry, index) => (
                <EnquiryItem
                  key={enquiry.Document}
                  enquiry={enquiry}
                  locked={enquiry.Locked}
                  lockedBy={enquiry.LockedUsername}
                  currentUser={username}
                  isHighlighted={true}
                  refetch={() => {
                    console.log('enq refetch')
                    refetch()
                  }}
                />
              ))
            ) : (
              <Typography variant="body2" sx={{ py: 2 }}>
                No enquiries to display.
              </Typography>
            )}
          </Grid>
          <Grid item xs={24}>
            <Typography variant="h6" sx={{ lineHeight: 1 }}>
              Other enquiries
            </Typography>
            {otherEnquiries.length > 0 ? (
              otherEnquiries.map((enquiry, index) => (
                <EnquiryItem
                  key={enquiry.Document}
                  enquiry={enquiry}
                  locked={enquiry.Locked}
                  lockedBy={enquiry.LockedUsername}
                  currentUser={username}
                  refetch={() => {
                    console.log('enq refetch')
                    refetch()
                  }}
                />
              ))
            ) : (
              <Typography variant="body2" sx={{ py: 2 }}>
                No enquiries to display.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Enquiries
