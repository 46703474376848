import React, { useContext, useEffect, useState } from 'react'
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  CssBaseline,
  Grid,
  TextField,
  Modal,
  Fade,
  Backdrop
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import axios from 'axios'
import { GlobalContext } from '../context/GlobalContext'
import Sidebar from './Sidebar'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import useApi from '../hooks/useApi'
import NiceModal from '@ebay/nice-modal-react'
import { deleteLicense, revokeLicense } from '../lib/api'
import { removeDuplicates, successToast } from '../lib/util'
import AddCustomerModal from './modals/AddCustomerModal'

dayjs.extend(relativeTime)

const Customer = () => {
  const [selectedGarage, setSelectedGarage] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('') // Search term state

  const {
    isLoading,
    data: customers,
    refetch
  } = useApi({
    url: 'https://vision-web-api-test.azurewebsites.net/api/connectplus/garageuserswithlicences'
  })

  if (isLoading) return null

  const customersNoDups = removeDuplicates(customers, 'GarageUserId')

  const handleOpenModal = (garage) => {
    NiceModal.show('licensesModal', { garage, refetch })
  }

  const getGarageLicenses = (garageName) => {
    const licenses = customersNoDups.filter((customer) => customer.GarageName === garageName)
    const activeCount = licenses.filter((customer) => customer.Active).length
    const inactiveCount = licenses.length - activeCount
    return { total: licenses.length, activeCount, inactiveCount }
  }

  const timeAgo = (date) => dayjs(date).fromNow()

  // Filtered garages based on search term
  const filteredGarages = Array.from(new Set(customersNoDups.map((c) => c.GarageName))).filter((garageName) =>
    garageName.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Container maxWidth="lg">
        <div className="flex justify-between items-center">
          <div>
            <Typography variant="h4" gutterBottom>
              Garage License Status
            </Typography>
          </div>
          <div className="flex items-center space-x-2">
            <TextField
              size="small"
              label="Search Garages"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
            />
            <Button variant="contained" color="primary" onClick={() => setModalOpen(true)}>
              Add Garage
            </Button>
            <AddCustomerModal
              open={modalOpen}
              onClose={async () => {
                await refetch()
                setModalOpen(false)
              }}
            />
          </div>
        </div>

        <Grid container spacing={3}>
          {filteredGarages.map((garageName) => {
            const { total, activeCount, inactiveCount } = getGarageLicenses(garageName)

            return (
              <Grid item xs={12} sm={6} md={4} key={garageName}>
                <Card
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '150px' // Standardized height
                  }}
                  onClick={() => handleOpenModal(garageName)}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="div">
                      {garageName.length > 25 ? `${garageName.slice(0, 23)}...` : garageName}
                    </Typography>

                    <Typography color="textSecondary">Total Licenses: {total}</Typography>
                    <Typography color="textSecondary">Active: {activeCount}</Typography>
                    <Typography color="textSecondary">Inactive: {inactiveCount}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </Box>
  )
}

export default Customer
