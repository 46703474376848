import NiceModal from '@ebay/nice-modal-react'
import { LockOpenOutlined, LockOutlined } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Icon,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import dayjs from 'dayjs'
import React, { useState, useContext, useRef, useEffect } from 'react'
import { GlobalContext } from '../context/GlobalContext'
import useApi from '../hooks/useApi'
import { ConsumablesBox, DocumentChip, RegPlate, StatusChip } from './EnquiryItem'
import { ModalContainerAnimateDown } from './gsys-ui/ModalContainer/ModalContainer'
import haxios from '../lib/haxios'
import { getImageFromBlobId, sendMessage } from '../lib/api'
import { Spinner } from './gsys-ui/Spinner/Spinner'
import ComposeMessage from './ComposeMessage'
import ConfirmationModal from './ConfirmationModal'
import { useEnquiryLock } from '../stores/useLockStore'

const EnquiryDetailsView = NiceModal.create(({ initialTab = 0, enquiry, currentUser, refetchEnquiries }) => {
  const { cinfo, apir } = useContext(GlobalContext)
  const modalContainer = useRef(null)
  const [error, setError] = useState(null)
  const [tabIndex, setTabIndex] = useState(initialTab)
  const { lockState, connected, unlock } = useEnquiryLock(enquiry.EnquiryId)

  const {
    isLoading: isLoadingLines,
    data: lines,
    refetch: refetchLines
  } = useApi({
    url: `https://api.aa-vision.com/api/connectplus/factor/response/${enquiry.EnquiryId}`
  })

  const {
    isLoading: isLoadingMessages,
    data: messages,
    refetch: refetchMessages
  } = useApi({
    url: `https://api.aa-vision.com/api/connectplus/factor/messages/${enquiry.EnquiryId}/true`,
    refetch: 20 * 1000,
    initial: []
  })

  const {
    isLoading: isLoadingEnq,
    data: enqData,
    refetch: refetchEnq
  } = useApi({
    url: `https://api.aa-vision.com/api/connectplus/factor/enquiry/${enquiry.EnquiryId}`,
    method: 'get',
    refetch: 5 * 1000,
    initial: enquiry
  })

  // Add useEffect to monitor status changes
  useEffect(() => {
    console.log('fetching lines')
    refetchLines()

    if (enqData?.Status === 'Ordered') {
      modalContainer.current?.closeModal()
      refetchEnquiries() // Refresh the parent list
    }
  }, [enqData?.Status])

  if (isLoadingLines) return null

  const handleTabChange = (event, newIndex) => {
    if (newIndex === 0) refetchLines()
    if (newIndex === 1) refetchMessages()
    setTabIndex(newIndex)
  }

  const isLockedByCurrentUser = lockState && lockState.username === currentUser
  const showUnlockButton = false //isLockedByCurrentUser || (lockedData.locked && cinfo.Admin)

  const handleUnlock = async () => {
    if (!lockState) return
    if (cinfo.Admin === false && isLockedByCurrentUser === false) return
    unlock()
    refetchEnquiries()
  }

  const messagesWithInitialImage = enqData.BlobStorageId
    ? [
        {
          BlobStorageId: enqData.BlobStorageId,
          Datetime: enqData.DatetimeCreated,
          EnquiryId: enqData.EnquiryId,
          Factor: false,
          FactorCompanyId: enqData.FactorCompanyId,
          Garage: true,
          MessageId: enqData.BlobStorageId,
          RecipientRead: false,
          Text: '',
          UserId: null,
          Username: null
        },
        ...messages
      ]
    : messages

  const handleModalClose = async () => {
    await handleUnlock()
    modalContainer.current?.closeModal()
    NiceModal.hide(EnquiryDetailsView)
  }

  return (
    <ModalContainerAnimateDown ref={modalContainer} onClose={handleModalClose}>
      <Card sx={{ width: '900px' }}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <EnquiryHeader
            handleModalClose={handleModalClose}
            apir={apir}
            enquiry={enqData}
            closeModal={modalContainer.current?.closeModal}
            refetchEnquiries={refetchEnquiries}
          />

          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Order Details" />
            <Tab label="Messages" />
          </Tabs>

          <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
            {error && <Typography color="error">{error}</Typography>}
            {tabIndex === 0 && lines && <DetailsTab lines={lines} apir={apir} />}
            {tabIndex === 1 && messages && (
              <MessagesTab
                enquiry={enqData}
                refetch={refetchMessages}
                messages={messagesWithInitialImage}
                locked={!!lockState}
                isLockedByCurrentUser={isLockedByCurrentUser}
              />
            )}
          </Box>
        </Box>
      </Card>
    </ModalContainerAnimateDown>
  )
})

const EnquiryHeader = ({ handleModalClose, apir, enquiry, closeModal, handleUnlock, refetchEnquiries }) => {
  const [isBackordered, setIsBackordered] = useState(false)
  const [isLoadingBackorder, setIsLoadingBackorder] = useState(true)
  const { lockState, connected, unlock } = useEnquiryLock(enquiry.EnquiryId)

  // Check if enquiry is backordered when component mounts
  useEffect(() => {
    const checkBackorderStatus = async () => {
      try {
        const response = await haxios.get(
          `https://melk.connectplus.parts/api/connectplus/backordered/check/${enquiry.EnquiryId}`
        )
        console.info('resp:', response)
        setIsBackordered(!!response.exists)
      } catch (error) {
        console.error('Error checking backorder status:', error)
      } finally {
        setIsLoadingBackorder(false)
      }
    }

    checkBackorderStatus()
  }, [enquiry.EnquiryId])

  const handleBackorderChange = async (event) => {
    const checked = event.target.checked
    try {
      if (checked) {
        await haxios.post(`https://melk.connectplus.parts/api/connectplus/backordered/add`, {
          EnqId: enquiry.EnquiryId
        })
        setIsBackordered(true)
      } else {
        await haxios.post(`https://melk.connectplus.parts/api/connectplus/backordered/delete`, {
          EnqId: enquiry.EnquiryId
        })
        setIsBackordered(false)
      }
    } catch (error) {
      console.error('Error updating backorder status:', error)
      // Revert checkbox state on error
      setIsBackordered(!checked)
    }

    refetchEnquiries()
  }

  const isRegPlate = enquiry.Reg && enquiry.Reg !== 'CONSUMABLES'

  const today = dayjs().startOf('day')
  const createdDate = dayjs(enquiry.DatetimeCreated)
  const modifiedDate = dayjs(enquiry.DatetimeModified)

  const isCreatedToday = createdDate.isSame(today, 'day')
  const isModifiedToday = modifiedDate.isSame(today, 'day')

  const formattedCreatedDate = isCreatedToday
    ? 'Today, ' + createdDate.format('HH:mm')
    : createdDate.format('DD/MM/YYYY, HH:mm')
  const formattedModifiedDate = isModifiedToday
    ? 'Today, ' + modifiedDate.format('HH:mm')
    : modifiedDate.format('DD/MM/YYYY, HH:mm')

  const timeSinceCreated = createdDate.fromNow()
  const timeSinceModified = modifiedDate.fromNow()

  return (
    <Box sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', padding: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ paddingRight: 1 }}>
            <StatusChip status={enquiry.Status} />
          </Box>
          <Box sx={{ paddingRight: 2 }}>
            <DocumentChip document={enquiry.Document} />
          </Box>
          <Box sx={{ marginRight: 1 }}>
            <LockedChip lockState={lockState} />
          </Box>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            {`${enquiry.CustomerKeyCode} - ${enquiry.GarageName}`}
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ paddingRight: 2 }}>
            {isRegPlate ? <RegPlate size={1.05}>{enquiry.Reg}</RegPlate> : <ConsumablesBox />}
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="h7" sx={{ lineHeight: 1 }}>
              {enquiry.Parts}
            </Typography>
            <Typography variant="caption">{enquiry.Notes || 'No additional notes provided.'}</Typography>
          </Box>
        </Box>
      </Box>

      {enquiry.Status === 'Garage Confirmed' && !isLoadingBackorder && (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <FormControlLabel
            control={<Checkbox checked={isBackordered} onChange={handleBackorderChange} color="primary" />}
            label="Mark as Backordered"
          />
        </Box>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 1 }}>
        <Box>
          <Box sx={{ lineHeight: 1 }}>
            <Typography variant="caption" sx={{ lineHeight: 1 }}>
              <span style={{ fontWeight: 600 }}>Created:</span> {formattedCreatedDate} ({timeSinceCreated})
            </Typography>
          </Box>
          <Box sx={{ lineHeight: 1 }}>
            <Typography variant="caption" sx={{ lineHeight: 1 }}>
              <span style={{ fontWeight: 600 }}>Modified:</span>{' '}
              {formattedModifiedDate === 'Invalid Date' ? 'Never' : formattedModifiedDate}{' '}
              {formattedModifiedDate === 'Invalid Date' ? '' : `(${timeSinceModified})`}
            </Typography>
          </Box>
        </Box>

        <Box>
          {false && (
            <Box sx={{ display: 'inline-block', paddingRight: 2 }}>
              <Button variant="text" startIcon={<LockOpenOutlined />} onClick={handleUnlock}>
                Unlock
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const LockedChip = ({ lockState }) => {
  const isLocked = !!lockState
  const username = lockState?.username

  return (
    <Box>
      <Tooltip title={`This enquiry is ${isLocked ? '' : 'un'}locked.`}>
        <Box
          sx={{
            background: isLocked ? 'orange' : 'green',
            color: 'white',
            display: 'inline-flex',
            borderRadius: '16px',
            height: '32px',
            minWidth: '32px',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 4px'
          }}
        >
          {isLocked ? <LockOutlined /> : <LockOpenOutlined />}
          {isLocked && <Box sx={{ fontSize: '14px', fontWeight: 600 }}>{username}</Box>}
        </Box>
      </Tooltip>
    </Box>
  )
}

const DetailsTab = ({ lines, apir }) => {
  const handleCantSupplyClick = () => {
    NiceModal.show(ConfirmationModal, {
      enquiryId: lines[0].EnquiryId,
      onCancelComplete: () => {
        //alert('Enquiry successfully cancelled')
      }
    })
  }

  return (
    <Box sx={{ padding: 2, maxHeight: '50vh' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Parts Ordered
      </Typography>
      <Box sx={{ border: '1px solid #ddd', borderRadius: '5px', padding: '10px', backgroundColor: '#f9f9f9' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'left' }}>Desc</th>
              <th style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'left' }}>Part Number</th>
              <th style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>Qty</th>
              <th style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'right' }}>Unit Price</th>
              <th style={{ borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'right' }}>Cost</th>
            </tr>
          </thead>
          <tbody>
            {lines
              .filter((line) => line.Confirmed)
              .map((line) => (
                <tr key={line.ResponseDetailId}>
                  <td style={{ padding: '10px', textAlign: 'left' }}>{line.ResponseDetailNotes}</td>
                  <td style={{ padding: '10px', textAlign: 'left' }}>{line.PartNumber}</td>
                  <td style={{ padding: '10px', textAlign: 'center' }}>{line.Qty}</td>
                  <td style={{ padding: '10px', textAlign: 'right' }}>
                    {line.Confirmed !== null ? `£${line.UnitPrice.toFixed(2)}` : 'Not confirmed.'}
                  </td>
                  <td style={{ padding: '10px', textAlign: 'right' }}>
                    {line.Confirmed !== null ? `£${line.Cost.toFixed(2)}` : ''}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Box>
      <div className="mt-2" />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {/* Delivery Date and Created Date */}
        {!!lines?.[0]?.DeliveryDate && (
          <Typography variant="body1">
            <strong>Delivery Date:</strong> {dayjs(lines[0].DeliveryDate).format('DD/MM/YYYY')}
          </Typography>
        )}
        <Typography variant="body1">
          <strong>Created:</strong> {dayjs(lines[0].DatetimeCreated).format('DD/MM/YYYY, HH:mm')}
        </Typography>
        <Typography variant="body1">
          <Button onClick={handleCantSupplyClick}>CAN'T SUPPLY</Button>
        </Typography>
      </div>
    </Box>
  )
}

const MessagesTab = ({ enquiry, messages, locked, refetch, isLockedByCurrentUser }) => {
  const messagesReversed = [...messages].reverse()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '50vh' }}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 2, display: 'flex', flexDirection: 'column-reverse', px: 2 }}>
        {messages && messages.length === 0 && <Typography>No messages found for this enquiry.</Typography>}
        {messages &&
          messagesReversed.map((message, ind) => (
            <Message
              key={message.MessageId}
              message={message}
              prevMsg={messagesReversed[ind + 1]}
              nextMsg={messagesReversed[ind - 1]}
            />
          ))}
      </Box>

      {!locked || isLockedByCurrentUser ? (
        <ComposeMessage enqId={enquiry.EnquiryId} refetchMessages={refetch} />
      ) : (
        <Typography variant="body2" sx={{ textAlign: 'center', color: 'gray' }}>
          You cannot send messages on a locked enquiry.
        </Typography>
      )}
    </Box>
  )
}

const Message = ({ message, prevMsg, nextMsg }) => {
  const [imageUrl, setImageUrl] = useState(null)
  const hasImage = !!message.BlobStorageId
  const hasText = !!message.Text
  const prevIsSameSender = prevMsg ? prevMsg.Factor === message.Factor && prevMsg.Garage === message.Garage : false
  const nextIsSameSender = nextMsg ? nextMsg.Factor === nextMsg.Factor && nextMsg.Garage === message.Garage : false

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const url = await getImageFromBlobId(message.BlobStorageId)
        setImageUrl(url)
      } catch (e) {
        console.error('Error fetching image:', e)
        setImageUrl('')
      }
    }

    if (imageUrl) return
    fetchImage()
  }, [message.BlobStorageId])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: message.Factor ? 'flex-end' : 'flex-start',
        mb: '3px'
      }}
    >
      <Box
        sx={{
          maxWidth: '70%',
          py: 1,
          px: 1.5,
          borderRadius: 3,
          backgroundColor: message.Factor ? 'dodgerblue' : '#e0e0e0',
          color: message.Factor ? 'white' : 'black',
          ...(message.Factor && !nextIsSameSender ? { borderBottomRightRadius: 0 } : {}),
          ...(message.Garage && !nextIsSameSender ? { borderBottomLeftRadius: 0 } : {})
        }}
      >
        {!!message.Username && prevIsSameSender === false && (
          <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 0 }}>
            {message.Username}
          </Typography>
        )}
        {hasImage && (
          <div
            className="rounded-md w-[250px] h-[250px] bg-contain bg-no-repeat bg-center border border-gray-300 flex items-center justify-center"
            style={{ backgroundColor: 'white', backgroundImage: `url(${imageUrl || '/img/whitepixel.png'})` }}
            onClick={() => (imageUrl ? NiceModal.show('lightboxModal', { url: imageUrl }) : null)}
          >
            {!imageUrl && <Spinner />}
          </div>
        )}
        {hasText && <Typography variant="body1">{message.Text}</Typography>}

        <Typography variant="caption" sx={{ display: 'block', mt: 0, textAlign: 'right', color: 'rgba(0,0,0,0.6)' }}>
          <div className="inline-flex items-center">
            {message.Datetime
              ? dayjs(message.Datetime).isSame(dayjs(), 'day')
                ? dayjs(message.Datetime).format('HH:mm')
                : dayjs(message.Datetime).format('DD/MM/YYYY HH:mm')
              : 'Unknown date'}
            {message.Factor &&
              (message.RecipientRead ? (
                <Icon fontSize="small" sx={{ marginLeft: 0.5 }}>
                  done_all
                </Icon>
              ) : (
                <Icon fontSize="small" sx={{ marginLeft: 0.5 }}>
                  done
                </Icon>
              ))}
          </div>
        </Typography>
      </Box>
    </Box>
  )
}

export default EnquiryDetailsView
