import React, { useRef, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { ModalContainerAnimateDown } from './gsys-ui/ModalContainer/ModalContainer';
import haxios from '../lib/haxios';

const API_URL = 'https://api.aa-vision.com';

const ConfirmationModal = NiceModal.create(({ enquiryId, onCancelComplete }) => {
    console.log('Enquiry ID:', enquiryId);

    const modal = useModal();
    const modalContainer = useRef(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleCancelEnquiry = async () => {
        setLoading(true);
        setError(null);

        try {
            console.log('Starting fetch...');

            // Step 1: Get enquiry details - haxios response is the data directly
            const enquiryDetails = await haxios.get(
                `${API_URL}/api/connectplus/factor/enquiry/${enquiryId}`
            );

            console.log('Enquiry Details:', enquiryDetails);

            if (!enquiryDetails?.EnquiryId) {
                console.error('Invalid enquiry details:', enquiryDetails);
                throw new Error('Invalid enquiry details received');
            }

            // Step 2: Post enquiry response with "Unavailable"
            const headerId = crypto.randomUUID();
            const responsePayload = {
                Enquiry: {
                    ...enquiryDetails,
                    Status: 'Quoted',
                },
                ResponseHeader: {
                    ResponseHeaderId: headerId,
                    EnquiryId: enquiryId,
                    TotalCost: 0,
                    UserId: enquiryDetails.UserId,
                    UserName: enquiryDetails.Username || enquiryDetails.PortalLockUsername,
                    Make: enquiryDetails.Make || '',
                    Model: enquiryDetails.Model || '',
                    Engine: enquiryDetails.Engine || '',
                    Fuel: enquiryDetails.Fuel || '',
                    GBox: enquiryDetails.GBox || '',
                    Notes: 'Part not available',
                    Automated: true,
                    AutomatedQuote: true,
                },
                ResponseDetail: [
                    {
                        ResponseHeaderId: headerId,
                        ResponseDetailId: crypto.randomUUID(),
                        PartNumber: 'UNAVAILABLE',
                        Notes: 'Part not available',
                        Qty: 0,
                        UnitPrice: 0,
                        DeliveryDate: new Date().toISOString().split('T')[0],
                    },
                ],
            };

            console.log('Posting response payload:', responsePayload);
            const responseResult = await haxios.post(
                `${API_URL}/api/connectplus/factor/enquiryresponse/`,
                responsePayload
            );
            console.log('Response result:', responseResult);

            // Step 3: Cancel the enquiry
            const cancelPayload = {
                Enquiry: {
                    ...enquiryDetails,
                    Status: 'Cancelled',
                    ResponseHeaderNotes: 'Cancellation due to stock issues',
                    CancelledBy: enquiryDetails.Username || enquiryDetails.PortalLockUsername,
                },
                ResponseHeader: {
                    ResponseHeaderId: headerId,
                    EnquiryId: enquiryId,
                    Make: enquiryDetails.Make || '',
                    Model: enquiryDetails.Model || '',
                    Engine: enquiryDetails.Engine || '',
                    Fuel: enquiryDetails.Fuel || '',
                    GBox: enquiryDetails.GBox || '',
                    Notes: 'Cancellation due to stock issues',
                    TotalCost: enquiryDetails.TotalCost || 0,
                    UserId: enquiryDetails.UserId,
                    UserName: enquiryDetails.Username || enquiryDetails.PortalLockUsername,
                }
            };

            console.log('Posting cancel payload:', cancelPayload);
            const cancelResult = await haxios.post(
                `${API_URL}/api/connectplus/factor/enquiry/cancel`,
                cancelPayload
            );
            console.log('Cancel result:', cancelResult);

            // Success handling
            setLoading(false);
            modal.remove();
            if (onCancelComplete) onCancelComplete();
        } catch (err) {
            console.error('Full error:', err);
            setLoading(false);
            setError(err.message || 'An error occurred while canceling the enquiry.');
        }
    };

    const handleClose = () => {
        modal.remove();
    };

    return (
        <ModalContainerAnimateDown ref={modalContainer}>
            <Box
                sx={{
                    padding: 3,
                    width: '400px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: 24,
                }}
            >
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Are you sure you can't supply this item?
                </Typography>
                {error && (
                    <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                        {error}
                    </Typography>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="outlined" onClick={handleClose} disabled={loading}>
                        NO
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleCancelEnquiry}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'YES'}
                    </Button>
                </Box>
            </Box>
        </ModalContainerAnimateDown>
    );
});

export default ConfirmationModal;