import { useMemo } from 'react'
import { create } from 'zustand'
import { useShallow } from 'zustand/shallow'

const useLockStore = create((set, get) => {
  const pendingRequests = new Map()

  return {
    socket: null,
    connected: false,
    locks: {},
    error: null,

    connect: (token) => {
      if (get().socket?.readyState === WebSocket.OPEN) {
        console.warn('WebSocket connection already exists')
        return
      }

      try {
        const socket = new WebSocket(`wss://melk.connectplus.parts/api/lockSocket?token=${encodeURIComponent(token)}`)

        socket.onopen = () => {
          set({ socket, connected: true, error: null })
        }

        socket.onclose = (event) => {
          pendingRequests.forEach((pending) => {
            pending.reject(new Error(`Connection closed: ${event.reason || 'Unknown reason'}`))
          })

          pendingRequests.clear()

          set({
            socket: null,
            connected: false,
            error: `Connection closed: ${event.reason || 'Unknown reason'}`
          })
        }

        socket.onerror = (error) => {
          set({ error: 'WebSocket error occurred' })
          console.error('WebSocket error:', error)
        }

        socket.onmessage = (event) => {
          try {
            const message = JSON.parse(event.data)

            switch (message.type) {
              case 'lockState':
                set({ locks: message.data })
                break

              case 'lockResponse':
              case 'unlockResponse': {
                const pending = pendingRequests.get(message.id)

                if (pending) {
                  pending.resolve(message.success)
                  pendingRequests.delete(message.id)
                }

                break
              }

              case 'error':
                set({ error: message.message })

                pendingRequests.forEach((pending) => {
                  pending.reject(new Error(message.message))
                })

                pendingRequests.clear()
                break

              default:
                console.warn('Unknown message type:', message.type)
            }
          } catch (error) {
            console.error('Error processing message:', error)
            set({ error: 'Error processing server message' })
          }
        }

        console.log('Successful connection to websocket')
        set({ socket })
      } catch (error) {
        console.error('Connection error:', error)
        set({ error: 'Failed to establish connection' })
      }
    },

    disconnect: () => {
      const { socket } = get()

      if (socket) {
        pendingRequests.forEach((pending) => {
          pending.reject(new Error('Disconnected'))
        })

        pendingRequests.clear()
        socket.close()
        set({ socket: null, connected: false, locks: [] })
      }
    },

    lockEnq: (id) => {
      const { socket, connected } = get()

      if (!socket || !connected) {
        return Promise.reject(new Error('Not connected to server'))
      }

      return new Promise((resolve, reject) => {
        pendingRequests.set(id, { resolve, reject })

        socket.send(
          JSON.stringify({
            type: 'lock',
            id
          })
        )

        setTimeout(() => {
          if (pendingRequests.has(id)) {
            pendingRequests.delete(id)
            reject(new Error('Lock request timeout'))
          }
        }, 5000)
      })
    },

    unlockEnq: (id) => {
      const { socket, connected } = get()

      if (!socket || !connected) {
        return Promise.reject(new Error('Not connected to server'))
      }

      return new Promise((resolve, reject) => {
        pendingRequests.set(id, { resolve, reject })

        socket.send(
          JSON.stringify({
            type: 'unlock',
            id
          })
        )

        setTimeout(() => {
          if (pendingRequests.has(id)) {
            pendingRequests.delete(id)
            reject(new Error('Unlock request timeout'))
          }
        }, 5000)
      })
    },

    clearError: () => set({ error: null })
  }
})

export const useEnquiryLock = (enqId) => {
  const lockState = useLockStore(useShallow((state) => state.locks[enqId]))
  const connected = useLockStore((state) => state.connected)
  const error = useLockStore((state) => state.error)
  const lockEnq = useLockStore((state) => state.lockEnq)
  const unlockEnq = useLockStore((state) => state.unlockEnq)

  return {
    lockState,
    connected,
    error,
    lock: () => lockEnq(enqId),
    unlock: () => unlockEnq(enqId)
  }
}

export default useLockStore
